import { styleVariants, styleOptions } from "./style";

export const getMaxDateOfArray = (dateList) =>
  new Date(Math.max.apply(null, dateList));
export const getMinDateOfArray = (dateList) =>
  new Date(Math.min.apply(null, dateList));
export const addDays = function (date, days) {
  date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
};
export const cloneState = (data) => JSON.parse(JSON.stringify(data));
export const twoDigits = (num) => (("" + num).length < 2 ? "0" + num : num);
export const toDate = (val) => new Date(val);
export const getItalianDate = (date) =>
  `${date.getDate()}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
export const getDisplayDate = (originalDate, withoutHour) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const compDate = new Date(originalDate);
  compDate.setHours(0, 0, 0, 0);
  return `${compDate.getTime() === today.getTime()
    ? "oggi"
    : compDate.getTime() === addDays(today, -1).getTime()
      ? "ieri"
      : getItalianDate(originalDate)
    }${withoutHour
      ? ""
      : ` alle ${twoDigits(originalDate.getHours())}:${twoDigits(
        originalDate.getMinutes()
      )}`
    }`;
};
export const zeroHours = (date) => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDisplayHour = (originalDate) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  return `${twoDigits(originalDate.getHours())}:${twoDigits(
    originalDate.getMinutes()
  )}`;
};
export const redirectAfterUserLogin = (history, user) => {
  const precUrl = sessionStorage.getItem("precUrl");
  if (precUrl) {
    history.push(precUrl);
    sessionStorage.removeItem("precUrl");
  } else {
    history.push(user.type === "ownerReport" ? "/owner/report" : "/owner/home");
  }
};
export const getScrollY = () =>
  window.pageYOffset || document.documentElement.scrollTop;

// export const numberToRGB = (colorNum, colors) => {
//   if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
//   return "hsl(" + ((colorNum * (360 / colors)) % 360) + ",100%,50%)";
// };

export const numberToRGB = (colorNum, colors) => {
  if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
  const angleMultiplier = 3; // Increase the separation angle
  return "hsl(" + ((colorNum * (360 / colors) * angleMultiplier) % 360) + ",100%,50%)";
};


export const toDateTime = (date) =>
  new Date(date).toISOString().slice(0, 19).replace("T", " ");


export const ottieniGiornoMeseAnno = (dataString) => {
  const data = new Date(dataString);
  // Ottieni giorno, mese e anno
  const giorno = data.getDate();
  const mese = String(data.getMonth() + 1).padStart(2, '0'); // Aggiunge uno zero iniziale se necessario
  const anno = data.getFullYear();
  return (giorno + '/' + mese + '/' + anno).toString();
}

// Logica di filtraggio
export const filterForPrintedAndSelectedOption = (data) => {
  const filter = localStorage.getItem('filterRes') || 'tutto';
  return data.map(data => {
    // Filtra le reservations in base al filtro
    const filteredReservations = data.reservations.filter(reservation => {
      if (reservation.printed !== 0) return false; // esclude le prenotazioni già stampate
      if (reservation.accettato === 0) return false; // esclude consegne e ritiro non ancora accettate
      if (filter === 'tutto') return reservation.selected_option === 'cucina' || reservation.selected_option === 'bar' || reservation.selected_option === 'fritti';
      return reservation.selected_option === filter;
    });

    // Se ci sono reservations filtrate, restituisci il tavolo aggiornato, altrimenti null
    return filteredReservations.length > 0 ? { ...data, reservations: filteredReservations } : null;
  }).filter(data => data !== null); // Filtra fuori i tavoli senza reservations

}


export const calcolaOrariDisponibili = (futureTimes, openingHour, openingMinutes, closingHour, closingMinutes, currentHour, currentMinutes) => {
  if (closingHour >= openingHour) {
    for (let hour = openingHour; hour <= closingHour; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        }
        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
          const formattedHour = (hour % 24).toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          futureTimes.push(`${formattedHour}:${formattedMinutes}`);
        }
      }
    }
  } else {
    for (let hour = openingHour; hour < 24; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        }
        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
          const formattedHour = (hour % 24).toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          futureTimes.push(`${formattedHour}:${formattedMinutes}`);
        }
      }
    }
    for (let hour = 0; hour <= closingHour; hour++) {
      const startMinutes = hour === openingHour ? openingMinutes : 0;
      for (let minutes = startMinutes; minutes < 60; minutes += 15) {
        if (hour === closingHour && minutes > closingMinutes) {
          break;
        }
        const formattedHour = (hour % 24).toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        futureTimes.push(`${formattedHour}:${formattedMinutes}`);
      }
    }
  }
}

export function rimuoviOrariOccupati(orariDisponibili, capacitaProduzione, indiceProduzione) {
  let orariRimasti = orariDisponibili;
  if (capacitaProduzione.length !== 0 && capacitaProduzione.some(el => el.orario === null)) {
    const itemInCucina = capacitaProduzione.filter(el => el.orario === null);
    // Calcola quanti intervalli di 15 minuti sono necessari
    const intervalliNecessari = Math.floor(itemInCucina[0].amount / indiceProduzione);
    // Rimuove gli intervalli dall'inizio dell'array
    orariRimasti = orariDisponibili.slice(intervalliNecessari);
  }
  //poi vado a verificare gli orari precisi
  capacitaProduzione.forEach(item => {
    if (item.orario?.includes("-")) {
      // Se l'orario è un intervallo (prima possibile), estrarre l'ora iniziale
      let [oraInizio] = item.orario.split(" - ");
      let indexInizio = orariRimasti.indexOf(oraInizio);
      if (indexInizio !== -1) {
        // Rimuovere gli orari dall'array se la quantità supera l'indice di produzione
        if (item.amount >= indiceProduzione) {
          orariRimasti.splice(indexInizio, 1);
        }
      }
    } else {
      // Se l'orario è singolo, cercare e rimuovere direttamente
      let index = orariRimasti.indexOf(item.orario);
      if (index !== -1) {
        // Rimuovere l'orario se la quantità supera l'indice di produzione
        if (item.amount >= indiceProduzione) {
          orariRimasti.splice(index, 1);
        }
      }
    }
  });

  return orariRimasti;
}

export const isOpen = (openingTime, closingTime) => {
  const [openHours, openMinutes, openSeconds] = openingTime.split(':').map(Number);
  const [closeHours, closeMinutes, closeSeconds] = closingTime.split(':').map(Number);

  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();

  const openTime = new Date();
  openTime.setHours(openHours, openMinutes, openSeconds, 0);

  const closeTime = new Date();
  closeTime.setHours(closeHours, closeMinutes, closeSeconds, 0);

  const currentTime = new Date();
  currentTime.setHours(currentHours, currentMinutes, currentSeconds, 0);

  if (closeTime < openTime) {
    // Locale aperto a cavallo della mezzanotte
    return currentTime >= openTime || currentTime < closeTime;
  } else {
    // Locale aperto nello stesso giorno
    return currentTime >= openTime && currentTime < closeTime;
  }
}

export const getFees = (amount) => {
  const stripeFees = (amount * 0.0325) + 0.25;
  const stripeIva = stripeFees * 0.22;
  const myOrdersFees = (amount * 0.005) + 0.10;
  return (stripeFees + stripeIva + myOrdersFees);


}

export const calculateTotalWithFees = (netAmount, isReturnFees, paymentOption) => {
  const stripePercentage = 0.0325; // 3.25%
  const stripeFixed = 0.25; // €0.25 fixed fee
  const stripeIva = 0.22; // 22% IVA

  const myPercentage = 0.005; // 0.5%
  const myFixed = 0.10; // €0.10 fixed fee

  if (paymentOption?.toLowerCase() === 'carta') {
    if (isReturnFees) {
      return (((netAmount + stripeFixed * (1 + stripeIva) + myFixed) /
        (1 - (stripePercentage * (1 + stripeIva) + myPercentage))) - netAmount).toFixed(2);
    } else {
      return ((netAmount + stripeFixed * (1 + stripeIva) + myFixed) /
        (1 - (stripePercentage * (1 + stripeIva) + myPercentage))).toFixed(2);
    }
  } else {
    return netAmount.toFixed(2)
  }
};




export const formatVariants = (variantString, refStyle) => {
  // Split by line breaks to get each category with its options
  if (variantString) {
    const categoryOptionsArray = variantString.split('\n\n').filter(Boolean);
    // Create a formatted list where categories are bolded and options are regular
    return categoryOptionsArray.map((categoryOptions, index) => {
      const [category, options] = categoryOptions.split(': ');
      if (refStyle === undefined) {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            <strong style={{ ...styleVariants, fontSize: '16px' }}>{category}:</strong>
            <span style={{ ...styleOptions, fontSize: '14px' }}> {options}</span>
          </div>
        );
      } else {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            <strong>{category}:</strong>
            <span> {options}</span>
          </div>
        );
      }

    });
  }
}