import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext, useState } from "react";
import { ReservationContext } from "../../../contexts/reservation.context";
import { styleVariants, styleOptions } from "../../../style";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 4,
    width: "100%",
  },
  media: {
    height: 140,
  },
  iconPadding: {
    padding: 4,
  },
  noPaddingBottom: { paddingBottom: 0 },
  checkbox: {
    margin: 0,
    padding: 0,
    float: "right",
  },
  spacingTop: {
    marginTop: theme.spacing(2) + ' !important',
  },
}));

const UserCartDish = ({ dish, dishIndex }) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(dish.amount);
  const [note, setNote] = useState(dish.note || "");
  const [showNote, setShowNote] = useState(!!dish.note);
  const [showRemove, setShowRemove] = useState(true);
  const [error, setError] = useState("");
  const { getDishPrice, removeFromCart, setDishNote, changeAmount } =
    useContext(ReservationContext);

  const handleChangeAmount = (value) => {
    setAmount(value);
    changeAmount(dishIndex, value);
  };

  const handleClickRemove = () => {
    removeFromCart(dishIndex);
  };

  const handleClickAddNote = () => {
    setShowNote(true);
  };

  const handleBlurNote = () => {
    setShowRemove(true);
    if (note.length <= 150) {
      saveNote();
    }
  };

  const handleFocusNote = () => {
    setShowRemove(false);
  };

  const handleClickRemoveNote = () => {
    setShowNote(false);
    removeNote();
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    if (newNote.length <= 150) {
      setNote(newNote);
      setError("");  // Clear the error if within limit
    } else {
      setError("Note cannot exceed 150 characters.");
    }
  };

  const saveNote = () => {
    if (note.length <= 150) {
      setDishNote(dishIndex, note);
    }
  };

  const removeNote = () => {
    setDishNote(dishIndex, null);
  };


  const getSelectedVariants = () => {
    // Creiamo un oggetto per raggruppare le opzioni selezionate
    const groupedVariants = {};

    dish.variants.forEach(variant => {
      const { name, position, options, selected } = variant;

      // Inizializziamo l'array per il nome della variante se non esiste già
      if (!groupedVariants[name]) {
        groupedVariants[name] = {
          options: [],
          position: position // Memorizziamo la posizione
        };
      }
      const selectedOptions = options.filter(option => selected.some(sel => sel.id === option.id));
      const selectedOptionNames = selectedOptions.map(option => option.label);
      groupedVariants[name].options.push(...selectedOptionNames); // Aggiungiamo le opzioni selezionate all'array corrispondente

    });

    // Ordiniamo le varianti in base alla posizione
    const sortedVariants = Object.entries(groupedVariants).sort((a, b) => {
      return a[1].position - b[1].position; // Ordina per posizione
    })

    // Ora convertiamo l'oggetto in una stringa formattata
    return sortedVariants.map(([variantName, { options }]) => (
      <Typography key={variantName} style={{ marginBottom: '4px' }}>
        <strong style={{ ...styleVariants }}>{variantName}:</strong>{' '}
        <span style={{ ...styleOptions }}>{options.join(', ')}</span>
      </Typography>
    )); // Utilizziamo Typography per formattare


  };


  return (
    <>
      <Grid container>
        <Grid item xs={9} className={classes.spacingTop}>
          <Typography variant="h5" sx={{ letterSpacing: 1, paddingBottom:'10px' }}><strong>{dish.name}</strong></Typography>
        </Grid>
        <Grid item xs={3} className={classes.spacingTop}>
          {getDishPrice(dish) && getDishPrice(dish) !== 0 ? (
            <Typography align="right">{(amount * getDishPrice(dish)).toFixed(2).toString().replace('.', ',')} €</Typography>
          ) : (
            ""
          )}
        </Grid>

        {dish.variants && dish.variants.length !== 0 && (
          <Grid item xs={12}>
            <div color="textSecondary" style={{ whiteSpace: 'break-spaces' }}>{getSelectedVariants()}</div>
          </Grid>
        )}
        {showNote && (
          <>
            <Grid item xs={12} className={classes.spacingTop} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TextField
                id="outlined-multiline-flexible"
                placeholder={"Note"}
                value={note}
                fullWidth
                onBlur={handleBlurNote}
                onFocus={handleFocusNote}
                onChange={handleNoteChange}
                error={!!error}
                helperText={error || `${note.length}/150`}
              />
              <IconButton onClick={showRemove ? handleClickRemoveNote : saveNote} style={{ marginTop: '-20px' }}>
                {showRemove ? <CloseIcon color="secondary" /> : <CheckIcon color="primary" />}
              </IconButton>
            </Grid>
          </>
        )}
        {!showNote && (
          <>
            <Grid item xs={12} className={classes.spacingTop}>
              <Button
                aria-label="delete"
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClickAddNote}
                className={classes.iconPadding}
              >
                Aggiungi nota
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={7} className={classes.spacingTop}>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount - 1)}
              className={classes.iconPadding}
              disabled={amount < 2}
            >
              <RemoveIcon fontSize="inherit" />
            </Button>
            <Button disableElevation disableFocusRipple disableRipple>
              {amount}
            </Button>
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount + 1)}
              className={classes.iconPadding}
            >
              <AddIcon fontSize="inherit" />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={5} className={classes.spacingTop}>
          <Button
            aria-label="delete"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.iconPadding}
            fullWidth
            onClick={handleClickRemove}
          >
            Rimuovi
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserCartDish;
